import React from 'react';
import { Helmet } from 'react-helmet';

// Note: You can find the matching <noscript> tag on src/html.tsx
export const LinkedInInsightsTag = (): JSX.Element => (
  <Helmet>
    <script
      type="text/plain"
      className="optanon-category-C0004"
    >
      {`_linkedin_partner_id = "${process.env.GATSBY_ROCHE_LINKEDIN_PARTNER_ID}";window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || [];window._linkedin_data_partner_ids.push(_linkedin_partner_id);`}
    </script>
    <script
      type="text/plain"
      className="optanon-category-C0004"
    >
      {'(function(l) {if (!l){window.lintrk = function (a, b) { window.lintrk.q.push([a, b]) };window.lintrk.q=[]}var s = document.getElementsByTagName("script")[0];var b = document.createElement("script");b.type = "text/javascript";b.async = true;b.src = "https://snap.licdn.com/li.lms-analytics/insight.min.js";s.parentNode.insertBefore(b, s);})(window.lintrk);'}
    </script>
  </Helmet>
);
