import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { DatalayerItem, TrackingService } from '@rocheglobal/component-library-125/src/services/tracking';
import { getComponent, blokToComponent } from '../components';
import { GoogleTagManager } from '../components/custom/google-tag-manager';
import { FacebookPixel } from '../components/custom/facebook-pixel';
import { InternalTracking } from '../components/custom/internal-tracking';
import { Hotjar } from '../components/custom/hotjar';
import {
  DomService,
  StoryblokService,
  LanguageService,
} from '../services';
import { EntryData } from './types';
import { SEO } from '../components/custom/seo';
import { LinkedInInsightsTag } from '../components/custom/linkedin-insights-tag';

interface StoryblokEntryProps {
  pageContext: EntryData;
  location: {
    state?: {
      maskUrl?: string;
    };
  };
}

interface StoryblokEntryData extends EntryData {
  getTagPageUrl?: string;
  initAOS?: boolean;
}

type StoryblokEntryState = StoryblokEntryData;

const searchModal = typeof document !== 'undefined' ? document.getElementById('global-modal') : null;
const RocheGlobalConfig = getComponent('roche-global-config') as React.ReactType;
const Header = 'roche-header' as React.ReactType;
const OffCanvas = 'roche-offcanvas-panel' as React.ReactType;
const Search = 'roche-search' as React.ReactType;
const MobileNavigation = 'roche-mobile-navigation' as React.ReactType;
const RocheAnimationManager = 'roche-animation-manager' as React.ReactType;

const parseEntryData = ({ pageContext }: StoryblokEntryProps): StoryblokEntryState => {
  const { story, settings } = pageContext;

  return {
    ...pageContext,
    ...DomService.getGlobalConfig(story, settings),
  };
};

// eslint-disable-next-line import/no-default-export
export default class StoryblokEntry extends Component<StoryblokEntryProps, StoryblokEntryState> {
  public static getDerivedStateFromProps(
    props: StoryblokEntryProps, state: StoryblokEntryState,
  ): StoryblokEntryState {
    return state.story.uuid !== props.pageContext.story.uuid
      ? parseEntryData(props)
      : null;
  }

  public constructor(props: StoryblokEntryProps) {
    super(props);
    this.state = parseEntryData(props);
    this.handleSearchModalTrigger = this.handleSearchModalTrigger.bind(this);
    this.handleCloseSearchModalTrigger = this.handleCloseSearchModalTrigger.bind(this);
  }

  public componentDidMount(): void {
    this.maskUrl();
    window.addEventListener('rocheLoginComplete', () => StoryblokService.redirect());

    LanguageService.getLanguages()
      .then((languages) => this.setState({ languages }));

    DomService.activateConsentScript();
    this.trackPageView();

    window.addEventListener('rocheModalTrigger', this.handleSearchModalTrigger);
    window.addEventListener('rocheModalClose', this.handleCloseSearchModalTrigger);
    this.setState({ initAOS: true });
  }

  public render(): JSX.Element {
    const {
      googleTagManagerId,
      story,
      navigation,
      footer,
      onClickNotice,
      languages,
      search,
      initAOS,
      ...globalConfig
    } = this.state;

    const canShowFooter = !story.content.hide_footer;

    return (
      <>
        <GoogleTagManager
          googleTagManagerId={googleTagManagerId}
        ></GoogleTagManager>
        <FacebookPixel />
        <InternalTracking />
        <Hotjar />
        <LinkedInInsightsTag />
        <SEO
          {...story.content.meta_tags}
          lang={story?.content?.language || story.lang}
          slug={story.full_slug}
          authorized_roles = {story.content.authorized_roles}
          category= {story?.content?.sub_category}
          tags= {story?.tag_list[0]}
          image= {story?.content?.teaser_image?.[0]?.src?.filename}
        ></SEO>
        <RocheGlobalConfig
          tagPageUrl={`${process.env.GATSBY_WEBSITE_URL}${(this.state.getTagPageUrl)}`}
          {...globalConfig}
        ></RocheGlobalConfig>
        <OffCanvas id="roche-offcanvas-menu">
          <MobileNavigation
            languages={JSON.stringify(languages)}
          >
            {!story.content.hide_navigation
            && navigation
            && blokToComponent({ blok: navigation?.content, getComponent })}
          </MobileNavigation>
        </OffCanvas>
        {this.state.isSearchActive
          ? ReactDOM.createPortal(<Search
            id="roche-search"
            total-results-for-query={search?.content?.total_results_for_query}
          />, searchModal)
          : null
        }
        <Header
          languages={JSON.stringify(languages)}
          hide-language-switch={story.content.hide_language_switch}
        >
          {!story.content.hide_navigation
          && navigation
          && blokToComponent({ blok: navigation?.content, getComponent })}
        </Header>
        <RocheAnimationManager can-initialize={initAOS}>
          {blokToComponent({ blok: story.content, getComponent, storyId: story.id })}
        </RocheAnimationManager>
        {canShowFooter && footer && blokToComponent({ blok: footer.content, getComponent })}
        {onClickNotice && blokToComponent({ blok: onClickNotice.content, getComponent })}
      </>
    );
  }

  private maskUrl(): void {
    const { maskUrl } = this.props.location.state || {};
    return maskUrl ? window.history.replaceState('', '', maskUrl) : undefined;
  }

  private handleSearchModalTrigger(event): void {
    if (event.detail === 'roche-search') {
      this.setState({ isSearchActive: true });
    }
  }

  private handleCloseSearchModalTrigger(): void {
    this.setState({ isSearchActive: false });
  }

  private trackPageView(): void {
    const { locale, hubPages } = this.state;

    /* eslint-disable @typescript-eslint/camelcase */
    TrackingService.push({
      event: 'page_view',
      additionalParameters: [{
        page_type: DomService.getPageType(
          this.state.story?.full_slug,
          this.state.story?.content.sub_category,
          hubPages,
          locale,
        ),
        page_category: this.state.story?.content.component,
        page_subcategory: this.state.story?.content.sub_category,
      }],
    } as DatalayerItem);
    /* eslint-enable @typescript-eslint/camelcase */
  }
}
