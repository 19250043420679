import React from 'react';
import { Helmet } from 'react-helmet';

export const InternalTracking = (): JSX.Element => (
  <Helmet>
    <script type="text/plain" className="optanon-category-C0002">
      {`
          !function() {
            var e = function(e, t, n) {
                if (n) {
                    var o = new Date;
                    o.setTime(o.getTime() + 24 * n * 60 * 60 * 1e3);
                    var a = "; expires=" + o.toGMTString()
                } else a = "";
                document.cookie = e + "=" + t + a + ";domain=.roche.com;path=/"
            };
            var t = new Image;
            t.addEventListener("error", function() {
                e("netLocation", "external")
            }), t.addEventListener("load", function() {
                e("netLocation", "internal")
            })
            t.src = "https://digitalregistry.roche.com/favicon.ico"
          }();
        `}
    </script>
  </Helmet>
);
