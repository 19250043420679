import React from 'react';
import { Helmet } from 'react-helmet';

// !Important on html.tsx the noscript is added for the facebook pixel
export const FacebookPixel = (): JSX.Element => (
  <Helmet>
    <script type="text/plain" className="optanon-category-C0004">{`
          !function(f,b,e,v,n,t,s)
          {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
          n.callMethod.apply(n,arguments):n.queue.push(arguments)};
          if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
          n.queue=[];t=b.createElement(e);t.async=!0;
          t.src=v;s=b.getElementsByTagName(e)[0];
          s.parentNode.insertBefore(t,s)}(window, document,'script',
          'https://connect.facebook.net/en_US/fbevents.js');
          fbq('init', '133638775532795');
          fbq('track', 'PageView');
        `}
    </script>
  </Helmet>
);
